// core
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Badge, Button, Table } from 'antd';

// utils
import { useTranslation } from 'react-i18next';
//hooks
import {
  useQueryFetchRequestList,
  useMutationRejectAll,
  useMutationRejectBargain,
  useMutationAcceptBargain,
} from '../../../../hooks/api';
// store
import { useAppStore } from 'store';

// types
import { IPropsType } from './types';
import { EDealStatusType, EGameType } from 'types/models';
import {
  EButtonSizeType,
  EButtonTypeType,
  EAvatarSizeType,
  EInputSizeType,
} from 'types/units';
import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';
import { ECardSizeType } from 'components/features/Cards/EmptyCard/types';

// components
import { EditIcon, StatIcon, SuccessIcon, TrashIcon } from 'components/icons';
import {
  EmptyCard,
  StickersBox,
  TableCellFloat,
  TableCellImage,
  TableCellStatus,
} from 'components/features';
import { FloatRange } from 'components/atoms';
// styles
import './index.scss';

// data
import { CSGOItem, DOTAItem } from './mockData';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
import { formatDate, formatPrice } from '../../../../utils/commons';
import { toast } from 'react-toastify';
import { useQueryFetchNotificationList } from '../../../../hooks/api';
import { useNavigate } from 'react-router-dom';
import { tab } from '@testing-library/user-event/dist/tab';
import { TableCellName } from '../../TableCells/TableCellName';
import { TableCellRarity } from '../../TableCells/TableCellRarity';

const transformListData = (list, old_price) => {
  return list.map((el, index) => {
    let { createdAt, isActive, price, user, userId, sku } = el;
    let { avatar, username, online } = user;
    return {
      id: sku,
      date: formatDate(createdAt, 'YYYY.MM.DD'),
      isActive,
      avatar: avatar.medium,
      username,
      price,
      old_price,
      userId,
      online,
    };
  });
};

const getStatus = (status, t) => {
  if (status === EDealStatusType.Success) {
    return (
      <span className="history-purchase__status history-purchase__status--success">
        {t('historyStatusDone')}
      </span>
    );
  } else if (status === EDealStatusType.Rejected) {
    return (
      <span className="history-purchase__status history-purchase__status--rejected">
        {t('historyStatusDeclined')}
      </span>
    );
  } else {
    return (
      <span className="history-purchase__status history-purchase__status--processing">
        {t('historyStatusPending')}
      </span>
    );
  }
};

const getPercentage = (price: number, proposal: number) => {
  const percentage: any = (100 - (100 / price) * proposal).toFixed(0);
  return `${percentage > 0 ? '-' : '+'}${Math.abs(percentage)}`;
};

const RequestTradeModal = ({
  modalId,
  item,
  onRemoveItem,
  onShowChart,
  onClose,
}: IPropsType) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { gameType, sModalClose, sModalOpen } = useAppStore();
  const [product, setProduct] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [isDecline, setIsDecline] = useState<boolean>(false);
  const { refetch: refetchNotificationList } =
    useQueryFetchNotificationList(false);

  const {
    data: rejectAllData,
    isLoading: isLoadingRejectAll,
    mutate: mutateRejectAll,
  } = useMutationRejectAll();

  const {
    data: rejectUserBargain,
    isLoading: isLoadingRejectUserBargain,
    mutate: mutateRejectUserBargain,
  } = useMutationRejectBargain();

  const {
    data: acceptBargainData,
    isLoading: isLoadingAcceptBargain,
    mutate: mutateAcceptBargain,
  } = useMutationAcceptBargain();
  const {
    data: requestList,
    isLoading: isLoadingRequestList,
    refetch: refetchRequestList,
  } = useQueryFetchRequestList(item.id);

  useEffect(() => {
    if (!isLoadingRequestList && requestList) {
      setTableData(transformListData(requestList, item.price));
      if (isDecline) {
        if (requestList.length) {
          setIsDecline(false);
        } else {
          onClose();
          sModalClose('requestTrade');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestList, isLoadingRequestList]);

  useEffect(() => {
    if (!isLoadingRejectAll && rejectAllData) {
      setTableData([]);
      onClose();
      sModalClose('requestTrade');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectAllData, isLoadingRejectAll]);

  useEffect(() => {
    if (gameType === EGameType.CSGO) {
      setProduct(CSGOItem);
    } else {
      setProduct(DOTAItem);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingAcceptBargain && acceptBargainData) {
      sModalClose('requestTrade');
      toast.success(t('toastSuccessBargainConfirm'));
      navigate('/inventory/pending');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptBargainData, isLoadingAcceptBargain]);

  useEffect(() => {
    if (rejectUserBargain && !isLoadingRejectUserBargain) {
      refetchRequestList(item.id);
      refetchNotificationList();
      setIsDecline(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectUserBargain, isLoadingRejectUserBargain]);

  const Stickers = useMemo(() => {
    let collection =
      gameType === EGameType.CSGO
        ? [...item.stickers, ...item.keychains]
        : item.gems;
    if (!collection.length) return null;
    return (
      <StickersBox
        collection={collection}
        sku={item.sku}
        gameType={gameType}
        containerClassName={'request-trade-modal__stickers'}
        itemClassName={'request-trade-modal__sticker'}
      />
    );
  }, [item, gameType]);

  const onDeclineAll = () => {
    mutateRejectAll({ sku: item.id });
  };

  const onShowPrice = data => {
    onShowChart(item);
    sModalOpen('priceTrade');
  };

  const onShowRemoveModal = () => {
    onRemoveItem(item.id);
    sModalOpen('removeTrade');
  };

  const onDecline = useCallback(
    offer => {
      mutateRejectUserBargain({ sku: item.id, userId: offer.userId });
    },
    [mutateRejectUserBargain, item],
  );

  const onAccept = useCallback(
    offer => {
      mutateAcceptBargain({ sku: item.id, userId: offer.userId });
    },
    [mutateAcceptBargain, item],
  );

  const dynamicColumns = useMemo(() => {
    return [
      {
        dataIndex: 'date',
        width: '130px',
      },
      {
        key: 'customer',
        width: width > MEDIA.M1024 ? '40%' : 'auto',
        render: (_, record) => (
          <div className="request-trade-modal__table-customer">
            <Badge
              dot={true}
              offset={[-8, 38]}
              status={record.online ? 'success' : 'default'}
            >
              <Avatar
                className="request-trade-modal__table-avatar"
                size={EAvatarSizeType.Default}
                src={record.avatar}
              />
            </Badge>
            <span className="request-trade-modal__table-username">
              {record.username} {record.online}
            </span>
          </div>
        ),
      },
      {
        key: 'price',
        width: width > MEDIA.M1024 ? '35%' : 'auto',
        render: (_, record) => (
          <div className="request-trade-modal__table-price">
            {t('offeredPrice')}: <span>$ {formatPrice(record.price)}</span>{' '}
            <span className="request-trade-modal__table-percent">
              ({t('difference')} {getPercentage(record.old_price, record.price)}
              %)
            </span>
          </div>
        ),
      },
      {
        key: 'action',
        render: (_, record) => (
          <div className="request-trade-modal__table-actions">
            <Button
              ghost
              type={EButtonTypeType.Primary}
              className="ant-btn-ghost--error"
              size={EButtonSizeType.Middle}
              onClick={() => onDecline(record)}
              disabled={isLoadingRejectUserBargain}
            >
              {t('decline')}
            </Button>
            <Button
              size={EButtonSizeType.Middle}
              ghost
              disabled={!record.online || isLoadingAcceptBargain}
              type={EButtonTypeType.Primary}
              onClick={() => onAccept(record)}
            >
              {t('confirmBtn')}
            </Button>
          </div>
        ),
      },
    ];
  }, [
    width,
    isLoadingAcceptBargain,
    isLoadingRejectUserBargain,
    t,
    onAccept,
    onDecline,
  ]);

  console.log('trade-modal', item);

  return (
    <div className="request-trade-modal">
      <div className="request-trade-modal__content">
        <div className="request-trade-modal__head">
          <div className="request-trade-modal__title">
            {t('bargainRequests')}
          </div>
          {width > MEDIA.M1024 && (
            <Button
              type={EButtonTypeType.Primary}
              className="ant-btn-primary--error"
              size={EButtonSizeType.Small}
              disabled={isLoadingRejectAll}
              onClick={() => {
                onDeclineAll();
              }}
            >
              {t('rejectAll')}
            </Button>
          )}
        </div>
        {item && (
          <Table
            showHeader={false}
            pagination={false}
            rowKey="id"
            className="request-trade-modal__product"
            scroll={{ x: '1400px' }}
            dataSource={[item]}
          >
            <Table.Column
              key="age"
              width={width > MEDIA.M1024 ? '370px' : 'auto'}
              render={() => item.id}
            />
            <Table.Column
              key="image"
              render={() => (
                <img
                  src={item.img}
                  alt={item.name}
                  className="request-trade-modal__product-img"
                />
              )}
            />
            <Table.Column
              key="type"
              render={() => (
                <>
                  <div className="request-trade-modal__product-type">
                    {item.marketName.first}
                    <div>{item.marketName.second}</div>
                  </div>
                </>
              )}
            />
            <Table.Column
              key="float"
              render={() => (
                <>
                  {item.float > 0 && (
                    <div className="request-trade-modal__product-float">
                      <div className="request-trade-modal__product-float-content">
                        <div className="request-trade-modal__product-float-grid">
                          {item.short}
                          <span>{item.float}</span>
                        </div>
                        <FloatRange
                          size={EFloatRangeSizeType.Small}
                          maxValue={item.float}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            />
            <Table.Column key="sticker" render={() => <>{Stickers}</>} />
            <Table.Column
              key="price"
              render={() => (
                <div className="request-trade-modal__product-price">
                  {t('price')}:<span>$ {formatPrice(item.price)}</span>
                  <Button
                    size={EButtonSizeType.Middle}
                    icon={<StatIcon />}
                    type={EButtonTypeType.Text}
                    className={'ant-btn-text--gray'}
                    onClick={() => onShowPrice(product)}
                  />
                </div>
              )}
            />
            <Table.Column
              key="status"
              render={() => (
                <div className="request-trade-modal__product-status">
                  {t('status')}: {getStatus(item.status, t)}
                </div>
              )}
            />
            <Table.Column
              key="action"
              render={() => (
                <Button
                  size={EButtonSizeType.Middle}
                  icon={<TrashIcon />}
                  type={EButtonTypeType.Text}
                  className="ant-btn-text--error"
                  onClick={() => onShowRemoveModal()}
                />
              )}
            />
          </Table>
        )}

        <div className="request-trade-modal__body">
          {width <= MEDIA.M1024 && (
            <Button
              type={EButtonTypeType.Primary}
              className="ant-btn-primary--error request-trade-modal__btn-decline-all"
              size={EButtonSizeType.Small}
              disabled={isLoadingRejectAll}
              onClick={() => {
                onDeclineAll();
              }}
            >
              {t('rejectAll')}
            </Button>
          )}

          <div className="request-trade-modal__table">
            <Table
              showHeader={false}
              pagination={false}
              loading={isLoadingRequestList}
              columns={dynamicColumns}
              dataSource={tableData}
              rowKey="id"
              scroll={{ x: '768px' }}
              locale={{
                emptyText: (
                  <div className="request-trade-modal__table-empty">
                    <EmptyCard
                      size={ECardSizeType.Small}
                      title={t('tradesEmptyTitle')}
                      description={t('tradesEmptyDesc')}
                    />
                  </div>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { RequestTradeModal };
