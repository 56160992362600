// core
import React from 'react';
import { Button } from 'antd';

//hooks

// utils

// locales
import { useTranslation } from 'react-i18next';
// types

// components(atoms)
import { PageMaintenanceSEO } from './SEO';

// styles
import './index.scss';

//tempo
import { toast } from 'react-toastify';
import { NotificationLetterIcon } from 'components/icons';
import { EmptyCard } from 'components/features';
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import { Link as RouterLink } from 'react-router-dom';

const onShowNotice = () => {
  toast.success('success Login');
  toast.info('info Login');
  toast.error('error Login');
  toast.warning('warning Login');
  toast('toast Login', {
    icon: () => <NotificationLetterIcon />,
  });
  toast('toast Login');
};

// func
const PageMaintenance = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageMaintenanceSEO />
      <div className="page-maintenance">
        <EmptyCard
          isError
          title={t('pages.maintenance.title')}
          description={t('pages.404.desc')}
        />
      </div>
    </>
  );
};

export { PageMaintenance };
