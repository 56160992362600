import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formatFloat, formatPrice, gameTypeSlug } from 'utils/commons';
import { useAppStore, useInventoryStore } from 'store';
// store

// types
import { EGridType, EInventoryAsideType } from 'types/units';
import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';
// components
import { ProductCardActions } from '../ProductCardActions';
import {
  SteamIcon,
  SuccessIcon,
  CardGlowLogoIcon,
  CardGlowIcon,
  CopyIcon,
} from 'components/icons';
import { FloatRange } from 'components/atoms';
import { StickerTooltip } from 'components/features';
import { Tooltip, Typography } from 'antd';

// styles
import './index.scss';

//data

const renderPriceDiscount = (price, marketPrice) => {
  let percent = '0.00';

  if (price) {
    percent = ((100 / marketPrice) * price - 100).toFixed(2);
  }

  return (
    <span
      className={
        'product-card-csgo__head-percent' +
        (Number(percent) > 0
          ? ' product-card-csgo__head-percent--overpayment'
          : '')
      }
    >
      {Number(percent) > 300 ? '>300' : percent}%
    </span>
  );
};

const { Paragraph } = Typography;
const ProductCardCSGO = ({
  data,
  size,
  cardType,
  isInventory,
  isInCart,
  isInExpose,
}) => {
  const { commission_percentage, sSetHeaderOffset } = useAppStore();
  const {
    inventoryAside,
    inventoryExpose,
    sSetInventoryExpose,
    sSetInventoryAside,
  } = useInventoryStore();

  const productColor = useMemo(() => {
    if (data?.tag?.rarity?.valueExtra) {
      return data.tag.rarity.valueExtra;
    }
    return data.nameColor;
  }, [data]);

  const onExposeToggle = data => {
    if (isInExpose) {
      let newExposeData = inventoryExpose.filter(item => item.id !== data.id);
      sSetInventoryExpose(newExposeData);
    } else {
      let product = { ...data };
      if (inventoryAside !== EInventoryAsideType.Expose) {
        sSetInventoryAside(EInventoryAsideType.Expose);
      }
      sSetHeaderOffset(true);
      let productPrice = product.price ? product.price : product.marketPrice;
      productPrice = formatPrice(productPrice, 2, false);

      sSetInventoryExpose([
        ...inventoryExpose,
        {
          ...product,
          price: productPrice,
          _saleCount: 1,
          _netPrice: formatPrice(
            productPrice - productPrice * commission_percentage,
            2,
            false,
          ),
        },
      ]);
    }
  };

  const getProductInner = useMemo(() => {
    return (
      <>
        <div className="product-card-csgo__head">
          <div className="product-card-csgo__head-price">
            ${' '}
            {isInventory
              ? formatPrice(data.marketPrice)
              : formatPrice(data.price)}
          </div>
          {!isInventory && (
            <div className="product-card-csgo__head-sale">
              {size === EGridType.Default && <SteamIcon />}
              {renderPriceDiscount(data.price, data.marketPrice)}
            </div>
          )}
        </div>

        <div className="product-card-csgo__image-wrapper">
          <div className="product-card-csgo__image">
            <div
              className="product-card-csgo__logo"
              style={{ color: `#${productColor}` }}
            >
              <CardGlowLogoIcon id={data.id} />
            </div>
            <img
              src={data.preview ? data.preview : data.image}
              alt={data.name}
            />
          </div>
        </div>

        <div className="product-card-csgo__attributes">
          {data.count > 1 && (
            <div className="product-card-csgo__attributes--counts">
              <div className="product-card-csgo__attributes--count">
                x{data.count}
              </div>
            </div>
          )}
          {size === EGridType.Default && data.keychains.length > 0 && (
            <div className="product-card-csgo__attributes--stickers">
              {data.keychains.map((charm, index) => (
                <div
                  className="product-card-csgo__attributes--stickers-item"
                  key={`${data.id}_${charm.value}_${index}`}
                >
                  <Tooltip
                    overlayClassName={'ant-tooltip--small'}
                    placement="top"
                    title={
                      <StickerTooltip
                        small
                        title={charm.title}
                        price={charm.valueExtra}
                      />
                    }
                  >
                    <img src={charm.preview} alt={charm.title} />
                  </Tooltip>
                </div>
              ))}
            </div>
          )}
          {size === EGridType.Default && data.stickers.length > 0 && (
            <div className="product-card-csgo__attributes--stickers">
              {data.stickers.map((sticker, index) => (
                <div
                  className="product-card-csgo__attributes--stickers-item"
                  key={`${data.id}_${sticker.value}_${index}`}
                >
                  <Tooltip
                    overlayClassName={'ant-tooltip--small'}
                    placement="top"
                    title={
                      <StickerTooltip
                        small
                        title={sticker.title}
                        price={sticker.valueExtra}
                      />
                    }
                  >
                    <img src={sticker.preview} alt={sticker.title} />
                  </Tooltip>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="product-card-csgo__quality">
          <div className="product-card-csgo__quality-head">
            {size === EGridType.Default && data.shortenExterior && (
              <div className="product-card-csgo__quality-head-title">
                {data.shortenExterior}
              </div>
            )}

            {data.float > 0 && (
              <div className="product-card-csgo__quality-head-value">
                {formatFloat(data.float)}
              </div>
            )}
            {size === EGridType.Small && data.stickers?.length > 0 ? (
              <div className="product-card-csgo__attributes--stickers">
                {data.stickers.map((sticker, index) => {
                  if (index < 2) {
                    return (
                      <div
                        className="product-card-csgo__attributes--stickers-item"
                        key={index}
                      >
                        <Tooltip
                          overlayClassName={'ant-tooltip--small'}
                          placement="top"
                          title={
                            <StickerTooltip
                              small
                              title={sticker.title}
                              price={sticker.valueExtra}
                            />
                          }
                        >
                          <img src={sticker.preview} alt={sticker.title} />
                        </Tooltip>
                      </div>
                    );
                  }
                  return null;
                })}
                <span>
                  {data.stickers.length > 2
                    ? '+ ' + (data.stickers.length - 2)
                    : ''}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
          {size === EGridType.Default && (
            <div className="product-card-csgo__quality--progress">
              <FloatRange
                isTooltip={true}
                size={EFloatRangeSizeType.Small}
                maxValue={data.float ? formatFloat(data.float) : 0}
              />
            </div>
          )}
        </div>
        <div className="product-card-csgo__content">
          <div className="product-card-csgo__content-title">
            <Tooltip
              overlayClassName={'ant-tooltip--small'}
              placement="top"
              title={
                <div className="product-card-csgo__content-title-tooltip">
                  {data.marketName.first}
                </div>
              }
            >
              {data.marketName.first}
            </Tooltip>
          </div>
          <div className="product-card-csgo__content-desc">
            {size === EGridType.Small ? (
              <span>{data.marketName.second}</span>
            ) : (
              <Paragraph
                copyable={{
                  icon: [
                    <CopyIcon key="copy-icon" />,
                    <CopyIcon key="copied-icon" />,
                  ],
                  text: data.originalMarketName,
                }}
              >
                <span>{data.marketName.second}</span>
              </Paragraph>
            )}

            {size === EGridType.Small && (
              <>
                {(isInCart || isInExpose) && (
                  <SuccessIcon className="product-card-csgo__content-icon-success" />
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }, [data, isInCart, isInExpose, isInventory, size, productColor]);

  return (
    <div
      className={
        'product-card-csgo' +
        (size === EGridType.Small ? ' product-card-csgo--small' : '') +
        (isInCart || isInExpose ? ' product-card-csgo--active' : '')
      }
    >
      <div
        className="product-card-csgo__glow"
        style={{
          color: `#${productColor}`,
        }}
      >
        <CardGlowIcon />
      </div>
      {isInventory ? (
        <div
          className="product-card-csgo__inner"
          onClick={() => {
            onExposeToggle(data);
          }}
        >
          {getProductInner}
        </div>
      ) : (
        <RouterLink
          to={`/market/${gameTypeSlug(data.provider)}/${
            data.seo?.sku ? data.seo?.sku : data.id
          }?id=${data.id}`}
          className="product-card-csgo__inner"
        >
          {getProductInner}
        </RouterLink>
      )}
      <div className="product-card-csgo__actions">
        <ProductCardActions
          data={data}
          isInCart={isInCart}
          isInExpose={isInExpose}
          cardType={cardType}
        />
      </div>
    </div>
  );
};

export { ProductCardCSGO };
