import React, { useState } from 'react';

import { Button, Radio } from 'antd';
import { useAppStore } from 'store';
// locale

// types
import { IPropsType } from './types';
import type { RadioChangeEvent } from 'antd';
import { EButtonSizeType, EButtonTypeType } from 'types/units';

// components

import { StarIcon } from 'components/icons';
import { ModalTeleport } from 'components/atoms';
import { StickerFilterModal } from '../../Modals/StickerFilterModal';

// styles
import './index.scss';
import { useTranslation } from 'react-i18next';

//data

const StickerFilter = ({
  data,
  current,
  modalData,
  modalCurrent,
  onChange,
  onChangeModal,
  isCharm,
}: IPropsType) => {
  const { sModalOpen } = useAppStore();
  const { t } = useTranslation();
  const onChangeFilter = ({ target: { value } }: RadioChangeEvent) => {
    onChange(value !== data[0].value ? [value] : []);
  };

  return (
    <div className="sticker-filter">
      <Radio.Group
        onChange={onChangeFilter}
        value={current[0] ? current[0] : data[0].value}
        className="sticker-filter__group"
      >
        {data.map(item => (
          <div className="sticker-filter__item" key={item.value}>
            <Radio value={item.value}>{item.title}</Radio>
          </div>
        ))}
      </Radio.Group>
      {!isCharm && (
        <>
          <Button
            size={EButtonSizeType.Middle}
            type={EButtonTypeType.Default}
            ghost
            block
            onClick={() => sModalOpen('stickerFilter')}
            className={
              'sticker-filter__btn ant-btn-ghost--grey' +
              (modalCurrent.length ? ' sticker-filter__btn--active' : '')
            }
          >
            <StarIcon />
            {t('stickerSettingsBtn')}
          </Button>

          <ModalTeleport
            centered
            modalId={'stickerFilter'}
            width={1045}
            modalClass={'sticker-filter-modal__wrapper'}
          >
            <StickerFilterModal
              modalId={'stickerFilter'}
              data={modalData}
              current={modalCurrent}
              onChange={onChangeModal}
            />
          </ModalTeleport>
        </>
      )}
    </div>
  );
};
export { StickerFilter };
