import React, { useEffect, useState } from 'react';
// types
import { Button, Checkbox, Collapse } from 'antd';

// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';

// types

import { IPropsType } from './types';
import {
  EGameType,
  EMarketFilterType,
  EMarketFilterUrlType,
} from 'types/models';
import { EButtonSizeType, EButtonTypeType } from 'types/units';
// components
import {
  PriceFilter,
  MultipleFilter,
  FloatFilter,
  StickerFilter,
  SelectSearchFilter,
} from 'components/features';
import { CrossIcon, PlusIcon } from 'components/icons';

// styles
import './index.scss';
import { isEqual } from 'lodash';

//data

const CatalogAside = ({
  filters,
  isOuterReset,
  onChange,
  onReset,
  isDisabledReset,
}: IPropsType) => {
  const { t } = useTranslation();
  const [currentFilters, setCurrentFilters] = useState<any>(filters);
  const { gameType } = useAppStore();
  const [isAttributesClean, setIsAttributesClean] = useState<boolean>(false);

  useEffect(() => {
    if (currentFilters) {
      setIsAttributesClean(isEqual(currentFilters, cleanedAttributes()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  useEffect(() => {
    if (isOuterReset) {
      onResetFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOuterReset]);

  const onChangeMarketCheaper = ({ target: { checked } }) => {
    const editedFilters = {
      ...currentFilters,
      CheaperThanSteam: {
        ...currentFilters.CheaperThanSteam,
        values: checked ? ['true'] : [],
      },
    };
    setCurrentFilters(editedFilters);
  };

  const onChangeFilter = (values, sku) => {
    setCurrentFilters({
      ...currentFilters,
      [sku]: {
        ...currentFilters[sku],
        values,
      },
    });
  };

  const onSaveFilters = () => {
    onChange(currentFilters);
  };

  const onChangeCountSticker = values => {
    let editedCurrentFilters = {
      ...currentFilters,
      Stickers: {
        ...currentFilters.Stickers,
        values: [],
      },
      CountSticker: {
        ...currentFilters.CountSticker,
        values,
      },
    };
    setCurrentFilters(editedCurrentFilters);
  };

  const onChangeCountCharm = values => {
    let editedCurrentFilters = {
      ...currentFilters,
      Charms: {
        ...currentFilters.Charms,
        values: [],
      },
      CountCharms: {
        ...currentFilters.CountCharms,
        values,
      },
    };
    setCurrentFilters(editedCurrentFilters);
  };
  const onChangeCustomStickers = values => {
    let editedCurrentFilters = {
      ...currentFilters,
      Stickers: {
        ...currentFilters.Stickers,
        values: values,
      },
      CountSticker: {
        ...currentFilters.CountSticker,
        values: values.length ? [values.length.toString()] : [],
      },
    };

    setCurrentFilters(editedCurrentFilters);
    onChange(editedCurrentFilters);
  };

  const cleanedAttributes = () => {
    return Object.keys(currentFilters).reduce((acc, key) => {
      let { urlType, sku, possibilityValues } = currentFilters[key];

      return {
        ...acc,
        [sku]: {
          ...currentFilters[key],
          values:
            urlType === EMarketFilterUrlType.Range
              ? [possibilityValues[0].value, possibilityValues[1].value]
              : [],
        },
      };
    }, {} as object);
  };
  const onResetFilters = () => {
    setCurrentFilters(cleanedAttributes());
    onReset();
  };

  const renderPriceFilter = filter => {
    return (
      <PriceFilter
        className={'catalog-aside__price'}
        data={filter.values}
        title={filter.title}
        onChange={values => {
          onChangeFilter(values, filter.sku);
        }}
      />
    );
  };

  const renderFilters = filters => {
    let multipleFilters = Object.values(filters)
      .filter(
        (item: any) =>
          item.type === EMarketFilterType.Side &&
          item.sku !== 'Float' &&
          item.sku !== 'CheaperThanSteam',
      )
      .sort((a, b) => {
        if (a && b) {
          // @ts-ignore
          return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
        }
        return 0;
      })
      .reduce((acc: any, item: any) => {
        if (item.sku === 'CountSticker') {
          if (filters['Stickers']?.possibilityValues.length) {
            return [
              ...acc,
              {
                label: filters['Stickers'].title,
                children: (
                  <StickerFilter
                    data={filters['CountSticker'].possibilityValues}
                    current={currentFilters['CountSticker'].values}
                    modalData={filters['Stickers'].possibilityValues}
                    modalCurrent={currentFilters['Stickers'].values}
                    onChange={values => {
                      onChangeCountSticker(values);
                    }}
                    onChangeModal={values => {
                      onChangeCustomStickers(values);
                    }}
                  />
                ),
              },
            ];
          } else {
            return acc;
          }
        }
        if (item.sku === 'CountCharms') {
          if (filters['Charms']?.possibilityValues.length) {
            return [
              ...acc,
              {
                label: filters['Charms'].title,
                children: (
                  <StickerFilter
                    isCharm
                    data={filters['CountCharms'].possibilityValues}
                    current={currentFilters['CountCharms'].values}
                    modalData={filters['Charms'].possibilityValues}
                    modalCurrent={currentFilters['Charms'].values}
                    onChange={values => {
                      onChangeCountCharm(values);
                    }}
                  />
                ),
              },
            ];
          } else {
            return acc;
          }
        }
        if (item.sku === 'ItemSet') {
          return [
            ...acc,
            {
              label: item.title,
              children: (
                <SelectSearchFilter
                  data={item.possibilityValues}
                  current={item.values}
                  onChange={values => {
                    onChangeFilter(values, item.sku);
                  }}
                />
              ),
            },
          ];
        } else {
          return [
            ...acc,
            {
              label: item.title,
              children: (
                <MultipleFilter
                  data={item.possibilityValues}
                  current={item.values}
                  onChange={values => {
                    onChangeFilter(values, item.sku);
                  }}
                />
              ),
            },
          ];
        }
      }, []);

    return gameType === EGameType.CSGO
      ? [
          // @ts-ignore
          ...multipleFilters,
          {
            label: 'Float',
            children: (
              <FloatFilter
                data={currentFilters['Float'].values}
                onChange={values => {
                  onChangeFilter(values, 'Float');
                }}
              />
            ),
          },
        ]
      : // @ts-ignore
        [...multipleFilters];
  };

  return (
    <div className="catalog-aside">
      {currentFilters && (
        <>
          {currentFilters.Price && renderPriceFilter(currentFilters.Price)}
          <div className="catalog-aside__card">
            <Collapse
              ghost
              defaultActiveKey={['0']}
              expandIcon={({ isActive }) =>
                isActive ? <CrossIcon /> : <PlusIcon />
              }
              items={renderFilters(currentFilters)}
            />
            <div className="catalog-aside__checkbox">
              <Checkbox
                checked={currentFilters.CheaperThanSteam.values[0]}
                onChange={onChangeMarketCheaper}
              >
                {t('cheaperThanSteam')}
              </Checkbox>
            </div>
            <div className="catalog-aside__actions">
              <Button
                size={EButtonSizeType.Middle}
                type={EButtonTypeType.Primary}
                ghost
                block
                onClick={() => onSaveFilters()}
              >
                {t('save')}
              </Button>
              <Button
                disabled={isAttributesClean && isDisabledReset}
                size={EButtonSizeType.Middle}
                type={EButtonTypeType.Primary}
                block
                onClick={() => onResetFilters()}
                className="ant-btn-primary--error catalog-aside__btn-reset"
              >
                {t('reset')}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { CatalogAside };
