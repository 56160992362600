import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Select, Button, Radio, Space, Switch } from 'antd';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
import { NavLink } from 'react-router-dom';
import { useDebounce } from 'hooks/general';
import type { RadioChangeEvent } from 'antd';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore, useAuthStore, useInventoryStore } from 'store';
import {
  useMutationSendProfileInfo,
  useQueryFetchProfileInfo,
  useQuerySearchInventoryItemsByName,
} from 'hooks/api';

// types
import {
  ESelectSizeType,
  ERadioButtonSizeType,
  EGridType,
  EButtonSizeType,
  EButtonTypeType,
} from 'types/units';
import {
  EGameType,
  EMarketFilterCategoryType,
  EMarketFilterUrlType,
  ESortType,
} from 'types/models';
import { IPropsType } from './types';
// components
import {
  ArrowDownIcon,
  GridSmallIcon,
  GridDefaultIcon,
  ReloadIcon,
  TrashIcon,
} from 'components/icons';
import { CategoryFilter } from 'components/features';
import { AppAutocomplete } from 'components/atoms';
import { BroomIcon } from '../../../../components/icons/Broom';

// styles
import './index.scss';

//data
import { sortOptions } from '../data';
const { Option } = Select;

const InventoryHead = ({
  categories,
  sort,
  search,
  isSteamUpdateProcessed,
  onChangeCategory,
  onChangeSort,
  onChangeSearch,
  onReset,
  onSteamReload,
  isInventory,
  isInventorySale,
  onSelectAll,
  onRemoveSelected,
  onRemoveAll,
  searchType,
}: IPropsType) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { steamId } = useAuthStore();
  const { inventoryGrid, sSetInventoryGrid } = useInventoryStore();
  const { gameType } = useAppStore();

  const [searchFocus, setSearchFocus] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState(search);
  const [debounceSearchValue, setDebounceSearchValue] = useState('');

  const [enabledListing, setEnabledListing] = useState<boolean>(true);

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    refetch: refetchProfileData,
  } = useQueryFetchProfileInfo(false);
  const {
    data: updateProfileData,
    isLoading: isLoadingUpdateProfile,
    mutate: mutateUpdateProfile,
  } = useMutationSendProfileInfo();

  useEffect(() => {
    if (profileData && !isLoadingProfileData) {
      setEnabledListing(profileData.trading);
    }
  }, [profileData, isLoadingProfileData]);

  useEffect(() => {
    if (updateProfileData && !isLoadingUpdateProfile) {
      refetchProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUpdateProfile, updateProfileData]);

  const scrollRef = useRef<any>(null);

  const [filteredSortOptions, setFilteredSortOptions] =
    useState<any>(sortOptions);

  useEffect(() => {
    if (gameType !== EGameType.CSGO) {
      setFilteredSortOptions(
        filteredSortOptions.filter(el => el.type !== ESortType.float),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameType]);

  const { data: searchData, isFetching: isFetchingSearch } =
    useQuerySearchInventoryItemsByName({
      params: {
        nameSearch: debounceSearchValue,
        provider: gameType,
        status: searchType,
      },
      steamId,
      enabled: !!debounceSearchValue,
    });

  const horizontalWheelScroll = event => {
    if (event.deltaY > 0) {
      scrollRef.current.scrollLeft += 100;
      event.preventDefault();
    } else {
      scrollRef.current.scrollLeft -= 100;
      event.preventDefault();
    }
  };

  useEffect(() => {
    let scrollEl = scrollRef?.current;
    if (scrollEl) {
      scrollEl.addEventListener('wheel', horizontalWheelScroll);
    }

    return () => {
      if (scrollEl) {
        scrollEl.removeEventListener('wheel', horizontalWheelScroll);
      }
    };
  }, []);

  useEffect(() => {
    setSearchValue(search);
  }, [search]);

  const onDebounceChange = () => {
    setDebounceSearchValue(searchValue);
  };

  const debouncedOnChange = useDebounce(onDebounceChange, 1000);

  const onChangeSortSelect = (value: string) => {
    let currentValue = filteredSortOptions.find(item => item.value === value);
    if (currentValue) {
      onChangeSort(currentValue);
    }
  };

  const onChangeGrid = ({ target: { value } }: RadioChangeEvent) => {
    sSetInventoryGrid(value);
  };

  const onSelectCategory = (value, item, category) => {
    let itemKey =
      category.urlType === EMarketFilterUrlType.Radio ? category.sku : item.sku;

    let prevValue = categories[category.sku].dataValues[itemKey];

    if (value.indexOf(EMarketFilterCategoryType.All) > -1) {
      //---all from category
      if (prevValue?.indexOf(value) > -1) {
        delete categories[category.sku].dataValues[itemKey];
      } else {
        categories[category.sku].dataValues[itemKey] = value;
      }
    } else {
      //---one from category
      if (prevValue) {
        let prevValuesArray = prevValue.split(',');
        let valueInArray = prevValuesArray.find(item => item === value);
        if (valueInArray) {
          //---delete item
          prevValuesArray = prevValuesArray.filter(item => item !== value);
        } else {
          //---check prevValue and add new item
          if (prevValue.indexOf(EMarketFilterCategoryType.All) > -1) {
            prevValuesArray = [value];
          } else if (
            item.possibilityValues.every(
              possibilityValue => possibilityValue.value !== prevValuesArray[0],
            )
          ) {
            prevValuesArray = [value];
          } else {
            prevValuesArray.push(value);
          }
        }

        //---update dataValues
        if (prevValuesArray.length) {
          categories[category.sku].dataValues[itemKey] =
            prevValuesArray.join(',');
        } else {
          delete categories[category.sku].dataValues[itemKey];
        }
      } else {
        //---add new value to empty dataValues
        categories[category.sku].dataValues[itemKey] = value;
      }
    }

    onChangeCategory({ ...categories });
  };
  const onFocusSearch = (status: boolean) => {
    setSearchFocus(status);
  };

  const renderCategoryGroup = (category: any) => {
    return category.possibilityGroups.map((item: any) => {
      if (item.possibilityValues.length) {
        let currentValueKey =
          category.urlType === EMarketFilterUrlType.Radio
            ? category.sku
            : item.sku;

        return (
          <CategoryFilter
            key={item.sku}
            label={item.title}
            sku={item.sku}
            icon={item.icon}
            data={item.possibilityValues}
            currentValue={category.dataValues[currentValueKey]}
            isCompact={gameType === EGameType.Dota}
            onSelect={value => {
              onSelectCategory(value, item, category);
            }}
          />
        );
      }
      return null;
    });
  };

  const onButtonSelectAll = () => {
    if (onSelectAll) {
      onSelectAll();
    }
  };
  const onButtonRemoveSelected = () => {
    if (onRemoveSelected) {
      onRemoveSelected();
    }
  };
  const onButtonRemoveAll = () => {
    if (onRemoveAll) {
      onRemoveAll();
    }
  };

  const onChangeSwitch = (value: boolean) => {
    mutateUpdateProfile({ trading: value });
  };

  useEffect(() => {
    if (searchValue?.length === 0) {
      onChangeSearch(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="inventory-head">
      <div
        className={`inventory-head__top ${
          isInventorySale ? 'inventory-head__top--spaced' : ''
        }`}
      >
        <div className="inventory-head__tabs">
          <Space.Compact block className="ant-space-compact--nav">
            <NavLink
              to="/inventory/stock"
              className={({ isActive }) =>
                isActive ? ' ant-space-compact-nav--active' : ''
              }
            >
              {t('inventory')}
            </NavLink>
            <NavLink
              to="/inventory/sales"
              className={({ isActive }) =>
                isActive ? ' ant-space-compact-nav--active' : ''
              }
            >
              {t('historySell')}
            </NavLink>
            <NavLink
              to="/inventory/pending"
              className={({ isActive }) =>
                isActive ? ' ant-space-compact-nav--active' : ''
              }
            >
              {t('waiting')}
            </NavLink>
          </Space.Compact>
        </div>
        {(isInventory || isInventorySale) && (
          <>
            <div
              className={
                'inventory-head__top-left' +
                (searchFocus ? ' inventory-head__top-left--search-focused' : '')
              }
            >
              {onSteamReload !== undefined && (
                <Button
                  size={EButtonSizeType.Large}
                  className={'inventory-head__btn-reload'}
                  disabled={isSteamUpdateProcessed}
                  icon={<ReloadIcon />}
                  onClick={() => onSteamReload()}
                />
              )}

              <AppAutocomplete
                className="inventory-head__autocomplete"
                isGetPopupContainer={true}
                popupClassName="inventory-head__autocomplete-dropdown"
                current={searchValue}
                options={searchData}
                isLoading={isFetchingSearch}
                isDefault={!!debounceSearchValue}
                size={ESelectSizeType.Large}
                popupMatchSelectWidth={false}
                onSearch={value => {
                  debouncedOnChange();
                  setSearchValue(value);
                }}
                onInputKeyDown={keyEvent => {
                  if (keyEvent.code === 'Enter') {
                    onChangeSearch(searchValue);
                  }
                }}
                onSelect={onChangeSearch}
                onReset={() => {
                  debouncedOnChange();
                  setSearchValue('');
                  onChangeSearch();
                }}
                onFocus={() => onFocusSearch(true)}
                onBlur={() => onFocusSearch(false)}
              />
            </div>

            <Select
              className="inventory-head__sort"
              popupClassName="ant-select-dropdown-lg"
              size={ESelectSizeType.Large}
              suffixIcon={<ArrowDownIcon />}
              value={sort?.value}
              onChange={onChangeSortSelect}
              dropdownAlign={{ offset: [0, 0] }}
              placeholder={t(filteredSortOptions[0].label)}
            >
              {filteredSortOptions?.length &&
                filteredSortOptions.map((el, index) => {
                  return (
                    <Option label={t(el.label)} value={el.value} key={index}>
                      {t(el.label)}
                    </Option>
                  );
                })}
            </Select>
            {isInventory && (
              <Button
                disabled={
                  categories?.values?.length === 0 &&
                  search?.length === 0 &&
                  !sort.type
                }
                size={EButtonSizeType.Large}
                className={'inventory-head__btn-reset'}
                icon={<BroomIcon />}
                onClick={() => onReset()}
              />
            )}
          </>
        )}
        {isInventorySale && (
          <div className="inventory-head__actions">
            <div className="inventory-head__switch">
              <Switch
                checked={enabledListing}
                loading={isLoadingUpdateProfile}
                onChange={onChangeSwitch}
              />
              <span>{t('enableListing')}</span>
            </div>
            <Button
              onClick={() => {
                onButtonSelectAll();
              }}
              size={EButtonSizeType.Large}
              className="inventory-head__btn-select"
            >
              {t('selectAll')}
            </Button>
            <Button
              onClick={() => {
                onButtonRemoveSelected();
              }}
              size={EButtonSizeType.Large}
              type={EButtonTypeType.Primary}
              className={'ant-btn-primary--error inventory-head__btn-unselect'}
            >
              {t('removeSelectedFromMarket')}
            </Button>
            <Button
              onClick={() => {
                onButtonRemoveAll();
              }}
              size={EButtonSizeType.Large}
              type={EButtonTypeType.Primary}
              className={'ant-btn-primary--error inventory-head__btn-unselect'}
            >
              {t('removeAll')}
            </Button>
          </div>
        )}
      </div>

      {isInventory && (
        <div className="inventory-head__bottom">
          {Object.keys(categories).length > 0 && (
            <div className="inventory-head__filters">
              <div
                className="inventory-head__categories inventory-head__scroll"
                ref={scrollRef}
              >
                {Object.values(categories).map(category => (
                  // @ts-ignore
                  <Fragment key={category.sku}>
                    {/* @ts-ignore*/}
                    {category.possibilityGroups.length > 0 && (
                      <div className="inventory-head__category">
                        {renderCategoryGroup(category)}
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          )}
          {width > MEDIA.M1024 && (
            <div className="inventory-head__actions">
              <Radio.Group
                value={inventoryGrid}
                size={ERadioButtonSizeType.Large}
                onChange={onChangeGrid}
              >
                <Radio.Button value={EGridType.Default}>
                  <GridDefaultIcon />
                </Radio.Button>
                <Radio.Button value={EGridType.Small}>
                  <GridSmallIcon />
                </Radio.Button>
              </Radio.Group>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
InventoryHead.defaultProps = {
  isInventorySale: false,
  searchType: undefined,
};
export { InventoryHead };
