import { useQuery } from '@tanstack/react-query';

//types
import { TProductInfoRequestParams } from 'types/api/product';

// services
import { GET_PRODUCT_INFO } from 'api/product';

export const useQueryFetchProductInfo = (
  itemId: TProductInfoRequestParams,
  query?: string | undefined | null,
  enabled?: boolean,
) => {
  return useQuery(
    ['useGetProductInfo', itemId, query],
    () => GET_PRODUCT_INFO(itemId, query),
    {
      enabled,
      refetchOnMount: () => 'always',
      onSuccess: data => {
        return data;
      },
      onError: (err: Error) => {
        const message = err
          ? err?.message
          : 'Error while fetching Product Info';
        console.error(message);
        return message;
      },
    },
  );
};
