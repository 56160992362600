import React, { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Tooltip, Typography } from 'antd';
import { useWindowSize } from 'react-use';
import { formatPrice, gameTypeSlug } from '../../../../../utils/commons';
// locale

// store
import { useAppStore, useInventoryStore } from 'store';

// types
import { EGridType, EInventoryAsideType } from 'types/units';

// components
import {
  SteamIcon,
  CardGlowIcon,
  SuccessIcon,
  CopyIcon,
} from 'components/icons';
import { ProductCardActions } from '../ProductCardActions';

import { StickerTooltip } from 'components/features';
// styles
import './index.scss';

//data
const renderPriceDiscount = (price, marketPrice) => {
  let percent = '0.00';

  if (price) {
    percent = ((100 / marketPrice) * price - 100).toFixed(2);
  }

  return (
    <span
      className={
        'product-card-dota__head-percent' +
        (Number(percent) > 0
          ? ' product-card-dota__head-percent--overpayment'
          : '')
      }
    >
      {Number(percent) > 300 ? '>300' : percent}%
    </span>
  );
};

const ProductCardDota = ({
  data,
  size,
  cardType,
  isInventory,
  isInCart,
  isInExpose,
}) => {
  const { Paragraph } = Typography;
  const { commission_percentage, sSetHeaderOffset } = useAppStore();
  const {
    inventoryAside,
    inventoryExpose,
    sSetInventoryExpose,
    sSetInventoryAside,
  } = useInventoryStore();

  const productColor = useMemo(() => {
    if (data?.tag?.rarity?.valueExtra) {
      return data.tag.rarity.valueExtra;
    }
    return 'transparent';
  }, [data]);

  const onExposeToggle = data => {
    if (isInExpose) {
      let newExposeData = inventoryExpose.filter(item => item.id !== data.id);
      sSetInventoryExpose(newExposeData);
    } else {
      let product = { ...data };
      if (inventoryAside !== EInventoryAsideType.Expose) {
        sSetInventoryAside(EInventoryAsideType.Expose);
      }
      sSetHeaderOffset(true);
      let productPrice = product.price ? product.price : product.marketPrice;
      productPrice = formatPrice(productPrice, 2, false);

      sSetInventoryExpose([
        ...inventoryExpose,
        {
          ...product,
          price: productPrice,
          _saleCount: 1,
          _netPrice: formatPrice(
            productPrice - productPrice * commission_percentage,
            2,
            false,
          ),
        },
      ]);
    }
  };

  const getProductInner = useMemo(() => {
    return (
      <>
        <div className="product-card-dota__head">
          <div className="product-card-dota__head--price">
            ${' '}
            {isInventory
              ? formatPrice(data.marketPrice)
              : formatPrice(data.price)}
          </div>
          {!isInventory && (
            <div className="product-card-dota__head--sale">
              {size === EGridType.Default && <SteamIcon />}
              {renderPriceDiscount(data.price, data.marketPrice)}
            </div>
          )}
        </div>
        <div className="product-card-dota__image-wrapper">
          <div className="product-card-dota__image">
            <img
              src={data.preview ? data.preview : data.image}
              alt={data.title}
            />
          </div>
        </div>
        <div className="product-card-dota__attributes">
          {data.count > 1 && (
            <div className="product-card-dota__attributes--counts">
              <div className="product-card-dota__attributes--count">
                x{data.count}
              </div>
            </div>
          )}
          {size === EGridType.Default && data.gems?.length > 0 && (
            <div className="product-card-dota__attributes--stickers">
              {data.gems.map((gem, index) => {
                if (index < 2) {
                  return (
                    <div
                      className="product-card-dota__attributes--stickers-item"
                      key={`${data.id}_${gem.value}_${index}`}
                    >
                      <Tooltip
                        overlayClassName={'ant-tooltip--small'}
                        placement="top"
                        title={
                          <StickerTooltip
                            small
                            title={gem.title}
                            description={gem.valueExtra}
                          />
                        }
                      >
                        <img src={gem.image} alt={gem.title} />
                      </Tooltip>
                    </div>
                  );
                }
                return null;
              })}
              <span>
                {data.gems.length > 2 ? '+ ' + (data.gems.length - 2) : ''}
              </span>
            </div>
          )}
        </div>
        <div
          className="product-card-dota__quality"
          style={{
            borderColor: `#${productColor}`,
          }}
        >
          <div className="product-card-dota__quality--head">
            <div
              className="product-card-dota__quality--head-title"
              style={{
                color: `#${productColor}`,
              }}
            >
              {data.tag?.rarity?.title}
            </div>
            {data.gems?.length && size === EGridType.Small ? (
              <div className="product-card-dota__attributes--stickers">
                {data.gems.map((gem, index) => {
                  if (index < 1) {
                    return (
                      <div
                        className="product-card-dota__attributes--stickers-item"
                        key={`${data.id}_${gem.value}_${index}`}
                      >
                        <Tooltip
                          overlayClassName={'ant-tooltip--small'}
                          placement="top"
                          title={
                            <StickerTooltip
                              small
                              title={gem.title}
                              description={gem.valueExtra}
                            />
                          }
                        >
                          <img src={gem.image} alt={gem.title} />
                        </Tooltip>
                      </div>
                    );
                  }
                  return null;
                })}
                <span>
                  {data.gems.length > 1 ? '+ ' + (data.gems.length - 1) : ''}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="product-card-dota__content">
          <div className="product-card-dota__content--title">
            {size === EGridType.Small ? (
              <>{data.marketName.first}</>
            ) : (
              <Paragraph
                copyable={{
                  icon: [
                    <CopyIcon key="copy-icon" />,
                    <CopyIcon key="copied-icon" />,
                  ],
                  text: data.originalMarketName,
                }}
              >
                <span>{data.marketName.first}</span>
              </Paragraph>
            )}
          </div>
          <div className="product-card-dota__content--desc">
            <span>{data?.tag?.slot?.title}</span>

            {size === EGridType.Small && (
              <>
                {(isInCart || isInExpose) && (
                  <SuccessIcon className="product-card-dota__content-icon-success" />
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }, [data, isInCart, isInExpose, isInventory, size, productColor, Paragraph]);

  return (
    <div
      className={
        'product-card-dota' +
        (size === EGridType.Small ? ' product-card-dota--small' : '') +
        (isInCart || isInExpose ? ' product-card-dota--active' : '')
      }
    >
      <div
        className="product-card-dota__glow"
        style={{
          color: `#${productColor}`,
        }}
      >
        <CardGlowIcon />
      </div>

      {isInventory ? (
        <div
          className="product-card-dota__inner"
          onClick={() => {
            onExposeToggle(data);
          }}
        >
          {getProductInner}
        </div>
      ) : (
        <RouterLink
          to={`/market/${gameTypeSlug(data.provider)}/${
            data.seo?.sku ? data.seo?.sku : data.id
          }?id=${data.id}`}
          className="product-card-dota__inner"
        >
          {getProductInner}
        </RouterLink>
      )}

      <div className="product-card-dota__actions">
        <ProductCardActions
          data={data}
          isInCart={isInCart}
          isInExpose={isInExpose}
          cardType={cardType}
        />
      </div>
    </div>
  );
};

export { ProductCardDota };
