import { $api } from 'utils/request';
import { stringify } from 'query-string';
// types
import {
  TProductInfoRequestParams,
  TProductInfoResponse,
  IRelatedProductsRequestParams,
  TRelatedProductsResponse,
  TProductMinimalPricesRequestParams,
} from 'types/api/product';

// services
export const GET_PRODUCT_INFO = async (
  itemId: TProductInfoRequestParams,
  query?: string | undefined | null,
) => {
  const url = `item/${itemId}${query ? '?id=' + query : ''}`;
  const response = await $api.get<TProductInfoResponse>(url);
  return response.data;
};
export const GET_RELATED_PRODUCTS = async ({
  id,
  payload,
}: IRelatedProductsRequestParams) => {
  const url = `similar-items/${id}`;
  const response = await $api.post<TRelatedProductsResponse>(url, payload);
  return response.data;
};
export const GET_PRODUCT_TRADES = async (query: any) => {
  const url = `market/trade/summary?${stringify(query)}`;
  const response = await $api.get<any>(url);
  return response.data;
};
export const GET_PRODUCT_TRADES_DETAILS = async (payload: any) => {
  const url = `market/trade/summary/deals`;
  const response = await $api.post<any>(url, payload);
  return response.data;
};

export const GET_PRODUCT_MINIMAL_PRICE = async (
  sku: TProductMinimalPricesRequestParams,
) => {
  const url = `market/trade/prices/${sku}`;
  const response = await $api.get<any>(url);
  return response.data;
};
