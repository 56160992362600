import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
import { objectToString } from 'utils/commons';

// locale

// store
import { useAppStore, useInventoryStore, useProfileStore } from 'store';
import {
  useQueryAvailableFilters,
  useQueryFetchProfileInfo,
  useQueryFetchSteamSettings,
  useQueryInventorySteamUpdate,
  useMutationInventoryFull,
} from 'hooks/api';

// types
import {
  ISortOption,
  EMarketFilterUnitedType,
  EMarketFilterCategoryType,
} from 'types/models';
import { EInventoryAsideType } from 'types/units';
import { ELoaderTypeType } from 'components/atoms/AppLoader/types';
import {
  ENotificationsType,
  ESocketEmitType,
  ESocketEventType,
} from 'types/api';

// components
import { AppLoader, ModalTeleport } from 'components/atoms';
import {
  SellProductModal,
  MobileExposeModal,
  MobileBottomNav,
  InstallExtensionModal,
} from 'components/features';
import { InventoryTrade } from './InventoryTrade';
import { InventoryHead } from '../InventoryHead';
import { InventoryEmpty } from './InventoryEmpty';
import { InventoryProduct } from './InventoryProduct';
import { InventoryExpose } from './InventoryExpose';
import { InventoryMobileNav } from './InventoryMobileNav';
import { InventoryScrollable } from './InventoryScrollable';

// styles
import './index.scss';

//data
import {
  getIniApiAttributes,
  getInitSortAttributes,
  sortOptions,
  updateUrlParams,
} from '../data';
import { useSocketSubscribe } from '../../../../hoc/SocketProvider';

const INVENTORY_TIME_UPDATE = 24 * 3600 * 1000;
// const INVENTORY_TIME_UPDATE = 30 * 1000;

const InventoryProducts = () => {
  const { width } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();
  const { gameType, sModalClose, sSetHeaderOffset } = useAppStore();
  const { profile } = useProfileStore();
  const {
    inventoryAside,
    inventoryExpose,
    inventoryExposeModal,
    inventorySteamUpdateDate,
    sSetInventoryProduct,
    sSetInventoryExpose,
    sSetInventoryExposeModal,
    sSetInventoryAside,
    sSetInventorySteamUpdateDate,
  } = useInventoryStore();

  const [isSteamUpdateProcessed, setIsSteamUpdateProcessed] =
    useState<boolean>(false);
  const [isSteamPreUpdateProcessed, setIsSteamPreUpdateProcessed] =
    useState<boolean>(false);
  const [isInventoryUpdateByDate, setIsInventoryUpdateByDate] =
    useState<boolean>(true);
  const [isSetDefaultProduct, setDefaultProduct] = useState<boolean>(false);
  const [isEnabledLoader, setIsEnabledLoader] = useState<boolean>(true);
  const [params, setParams] = useState<any>(null);

  const [categoriesAttributes, setCategoriesAttributes] = useState<null | any>(
    null,
  );
  const [searchAttributes, setSearchAttributes] = useState<string>('');
  const [sortAttributes, setSortAttributes] = useState<ISortOption>(
    getInitSortAttributes(location.search),
  );

  const [inventoryStatus, setInventoryStatus] = useState<any>({
    totalItems: 0,
    processedItems: 0,
  });
  const [lastNotification, setLastNotification] = useState<any | null>(null);
  const { refetch: refetchProfile } = useQueryFetchProfileInfo(false);

  const { data: filtersData, isLoading: isLoadingFilters } =
    useQueryAvailableFilters({ provider: gameType });

  const { isFetching: isFetchingSteamUpdate, refetch: refetchSteamUpdate } =
    useQueryInventorySteamUpdate({
      provider: gameType,
    });

  const onSocketNotificationHandler = data => {
    console.log('onSocketNotificationHandler', data);
    setLastNotification(data);
  };

  useSocketSubscribe(
    ESocketEventType.Notification,
    onSocketNotificationHandler,
  );

  const onSocketInventoryFetchStartHandler = data => {
    console.log('onSocketInventoryFetchStartHandler', data);
    setInventoryStatus({ totalItems: data.totalItems, processedItems: 0 });
  };

  const onSocketInventoryFetchHandler = data => {
    console.log('onSocketInventoryFetchHandler', data);
    mutateInventoryFull(params);
    setInventoryStatus(prevData => {
      return { ...prevData, processedItems: data.processedItems };
    });
  };

  const onSocketInventoryFetchFinishHandler = data => {
    console.log('onSocketInventoryFetchFinishHandler', data);
    setInventoryStatus(prevData => {
      return { ...prevData, processed: data.totalItems };
    });
  };

  useSocketSubscribe(
    ESocketEventType.InventoryFetchStart,
    onSocketInventoryFetchStartHandler,
  );

  useSocketSubscribe(
    ESocketEventType.InventoryFetch,
    onSocketInventoryFetchHandler,
  );

  useSocketSubscribe(
    ESocketEventType.InventoryFetchFinish,
    onSocketInventoryFetchFinishHandler,
  );

  const {
    mutate: mutateInventoryFull,
    inventoryItems,
    isIdle: isIdleInventoryFull,
    isLoading: isLoadingInventoryFull,
  } = useMutationInventoryFull();

  useEffect(() => {
    if (inventoryItems?.length && isLoadingInventoryFull) {
      setIsEnabledLoader(true);
    }
  }, [isLoadingInventoryFull, inventoryItems]);

  const {
    data: steamData,
    isLoading: isLoadingSteamData,
    refetch: refetchSteamSettings,
  } = useQueryFetchSteamSettings();

  //---set to default after unmount
  useEffect(() => {
    refetchProfile();
    //---update steam
    let currentTime = Date.now();

    if (currentTime - inventorySteamUpdateDate > INVENTORY_TIME_UPDATE) {
      setIsInventoryUpdateByDate(false);
      // setIsSteamPreUpdateProcessed(true);
      refetchSteamUpdate();
    }
    return () => {
      if (inventoryAside !== EInventoryAsideType.Empty) {
        sSetInventoryAside(EInventoryAsideType.Empty);
        sSetInventoryProduct(null);
        sSetInventoryExpose([]);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInventoryUpdateByDate && !isFetchingSteamUpdate) {
      let time = Date.now();
      sSetInventorySteamUpdateDate(time);
      setIsInventoryUpdateByDate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInventoryUpdateByDate, isFetchingSteamUpdate]);

  useEffect(() => {
    if (lastNotification?.type === ENotificationsType.InventoryUpdated) {
      setIsEnabledLoader(false);
      setIsSteamUpdateProcessed(false);
      mutateInventoryFull(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastNotification, lastNotification?.type]);

  useEffect(() => {
    if (inventoryStatus) {
      if (inventoryStatus.totalItems === inventoryStatus.processedItems) {
        console.log('setIsSteamPreUpdateProcessed');
        // setIsSteamPreUpdateProcessed(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(inventoryStatus)]);

  //---create filters default collection
  useEffect(() => {
    if (filtersData) {
      sSetHeaderOffset(true);
      let { categoriesCollection, searchCollection } = getIniApiAttributes(
        filtersData,
        location.search,
        gameType,
      );

      setCategoriesAttributes(categoriesCollection);
      setSearchAttributes(searchCollection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData]);

  //---create params collection and get items from request
  useEffect(() => {
    if (categoriesAttributes) {
      //---change url
      let objectUrlParams = updateUrlParams(
        categoriesAttributes,
        sortAttributes,
        searchAttributes,
        gameType,
      );

      // @ts-ignore
      if (Object.keys(objectUrlParams).length) {
        navigate(
          {
            pathname: '/inventory/stock',
            search: `?${objectToString(objectUrlParams)}`,
          },
          { replace: true },
        );
      } else {
        navigate({ pathname: '/inventory/stock' }, { replace: true });
      }

      //---create api format
      //---categories
      let tags: any = [];
      // @ts-ignore
      Object.values(categoriesAttributes).forEach<Record<any, any>>(
        (item: any) => {
          Object.keys(item.dataValues).forEach(key => {
            let categoryValues = item.dataValues[key];

            if (categoryValues) {
              let filterValues: any[] = [];
              if (categoryValues.indexOf(EMarketFilterCategoryType.All) > -1) {
                let parts = categoryValues.split('_');
                let group = item.possibilityGroups.find(
                  i => i.sku === parts[1],
                );
                filterValues = group.possibilityValues.at(-1).valueExtra;
              } else {
                let categoryValuesArray = categoryValues.split(',');
                filterValues = [...categoryValuesArray];
              }

              tags = [
                ...(tags as []),
                {
                  filterValues,
                  unitedOperator: EMarketFilterUnitedType.Or,
                },
              ];
            }
          });
        },
      );

      let tagParams = tags.length ? { tags } : {};
      //---sort
      let sortParams = sortAttributes?.typeValue
        ? {
            sort: { [sortAttributes.type as string]: sortAttributes.typeValue },
          }
        : {};

      //---search
      let searchParams = searchAttributes
        ? { nameSearch: searchAttributes }
        : {};

      setParams({
        provider: gameType,
        ...tagParams,
        ...sortParams,
        ...searchParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortAttributes,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(categoriesAttributes),
    searchAttributes,
  ]);

  //---fill inventory product sidebar
  useEffect(() => {
    if (steamData?.tradeOfferLink && !!params) {
      mutateInventoryFull(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params), steamData]);

  //---fill inventory product sidebar
  useEffect(() => {
    if (
      inventoryItems?.length > 0 &&
      !isSetDefaultProduct &&
      width > MEDIA.M1024
    ) {
      sSetInventoryProduct(inventoryItems[0]);
      sSetInventoryAside(EInventoryAsideType.Product);
      setDefaultProduct(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryItems?.length, isSetDefaultProduct]);

  const onChangeCategory = (categories: any) => {
    setCategoriesAttributes(categories);
  };
  const onChangeSort = (data: any) => {
    setSortAttributes(data);
  };

  const onChangeSearch = (data: any) => {
    if (data === '') {
      onResetAllFilters();
    } else {
      setSearchAttributes(data);
    }
  };

  const onResetAllFilters = () => {
    setSortAttributes(sortOptions[3]);
    let categories = Object.keys(categoriesAttributes!).reduce((acc, key) => {
      let { sku } = categoriesAttributes![key];

      return {
        ...acc,
        [sku]: {
          ...categoriesAttributes![key],
          dataValues: {},
        },
      };
    }, {} as object);
    setCategoriesAttributes(categories);
    setSearchAttributes('');
  };
  const onUpdateSteamData = () => {
    refetchSteamSettings();
  };

  const onSteamReload = () => {
    // setIsSteamPreUpdateProcessed(true);
    setIsSteamUpdateProcessed(true);
    setIsInventoryUpdateByDate(false);
    refetchSteamUpdate();
    setSortAttributes(sortOptions[0]);
    let categories = Object.keys(categoriesAttributes!).reduce((acc, key) => {
      let { sku } = categoriesAttributes![key];

      return {
        ...acc,
        [sku]: {
          ...categoriesAttributes![key],
          dataValues: {},
        },
      };
    }, {} as object);
    setCategoriesAttributes(categories);
    setSearchAttributes('');
  };

  const onExposedMultiple = () => {
    //---erase data
    sSetInventoryAside(EInventoryAsideType.Empty);
    sSetInventoryProduct(null);
    sModalClose('sellProduct');
    sModalClose('mobileExpose');
    sSetInventoryExposeModal(null);
    sSetInventoryExpose([]);

    //---reload inventory
    mutateInventoryFull(params);
  };
  const onExposedSingle = () => {
    let inventoryExposeModalId = inventoryExposeModal.id;

    //---check expose list
    let exposedFiltered = inventoryExpose.filter(
      item => item.id !== inventoryExposeModalId,
    );
    if (exposedFiltered.length !== inventoryExpose.length) {
      sSetInventoryExpose(exposedFiltered);
    }

    //---erase data
    sModalClose('sellProduct');
    sSetInventoryExposeModal(null);

    if (inventoryAside === EInventoryAsideType.Product) {
      sSetInventoryAside(EInventoryAsideType.Empty);
      sSetInventoryProduct(null);
    } else if (
      inventoryAside === EInventoryAsideType.Expose &&
      !exposedFiltered.length
    ) {
      sModalClose('mobileExpose');
      sSetInventoryAside(EInventoryAsideType.Empty);
    }

    //---reload inventory
    mutateInventoryFull(params);
  };

  const onCloseModalSellProduct = () => {
    if (width < MEDIA.M1024) {
      sSetInventoryProduct(null);
    }
    sSetInventoryExposeModal(null);
  };

  return (
    <div className="inventory inventory--catalog">
      {isLoadingFilters ||
      categoriesAttributes === null ||
      isLoadingSteamData ||
      steamData === null ? (
        <AppLoader type={ELoaderTypeType.Global} />
      ) : (
        <>
          <InventoryHead
            isInventory={true}
            categories={categoriesAttributes}
            search={searchAttributes}
            sort={sortAttributes}
            isSteamUpdateProcessed={isSteamUpdateProcessed}
            onChangeCategory={onChangeCategory}
            onChangeSort={onChangeSort}
            onReset={onResetAllFilters}
            onChangeSearch={onChangeSearch}
            onSteamReload={onSteamReload}
          />
          <div className="inventory-products">
            {/*PRODUCTS | TRADE*/}
            {steamData?.tradeOfferLink ? (
              <>
                {/*NOTE: for correct full loading flow need add isSteamUpdateProcessed flag*/}
                {isSteamPreUpdateProcessed ||
                (isLoadingInventoryFull && isEnabledLoader) ||
                isIdleInventoryFull ? (
                  <div className="inventory-products__refetch-loader">
                    <AppLoader type={ELoaderTypeType.Relative} />
                    {isSteamPreUpdateProcessed && (
                      <div className="inventory-products__refetch-status">
                        Importing inventory {inventoryStatus.totalItems}/
                        {inventoryStatus.processedItems}
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    {inventoryItems?.length > 0 || !isEnabledLoader ? (
                      // PRODUCT | EXPOSE | EMPTY
                      <div
                        className={
                          'inventory-products__grid' +
                          (inventoryAside === EInventoryAsideType.Expose
                            ? ' inventory-products__grid--expose'
                            : '') +
                          (inventoryAside === EInventoryAsideType.Product
                            ? ' inventory-products__grid--product'
                            : '')
                        }
                      >
                        <div className="inventory-products__main">
                          <InventoryScrollable
                            inventoryItems={inventoryItems}
                          />
                        </div>
                        {width > MEDIA.M1024 ? (
                          <>
                            {inventoryAside === EInventoryAsideType.Product && (
                              <div className="inventory-products__aside-product">
                                <InventoryProduct />
                              </div>
                            )}
                            {inventoryAside === EInventoryAsideType.Expose && (
                              <div className="inventory-products__aside-expose">
                                <InventoryExpose
                                  onExposedMultiple={onExposedMultiple}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {inventoryExpose.length > 0 && (
                              <MobileBottomNav
                                breakPoint={1024}
                                className="inventory-products__mobile-nav"
                              >
                                <InventoryMobileNav
                                  onExposedMultiple={onExposedMultiple}
                                />
                              </MobileBottomNav>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="inventory-products__simple-content">
                        <InventoryEmpty
                          width={width}
                          isSteamUpdateProcessed={isSteamUpdateProcessed}
                          onSteamReload={onSteamReload}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="inventory-products__simple-content">
                <InventoryTrade
                  onUpdate={() => {
                    onUpdateSteamData();
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}

      <ModalTeleport
        centered
        modalId={'installExtension'}
        width={368}
        modalClass={'install-extension-modal__wrapper'}
      >
        <InstallExtensionModal modalId={'installExtension'} />
      </ModalTeleport>

      <ModalTeleport
        centered
        modalId={'sellProduct'}
        width={1250}
        onClose={() => onCloseModalSellProduct()}
        modalClass={'sell-product-modal__wrapper'}
      >
        <SellProductModal
          modalId={'sellProduct'}
          onExposedSingle={onExposedSingle}
        />
      </ModalTeleport>

      {width <= MEDIA.M1024 && (
        <ModalTeleport
          centered
          modalId={'mobileExpose'}
          width={1024}
          modalClass={'mobile-expose-modal__wrapper'}
          zIndex={990}
        >
          <MobileExposeModal
            modalId={'mobileExpose'}
            onExposedMultiple={onExposedMultiple}
          />
        </ModalTeleport>
      )}
    </div>
  );
};

export { InventoryProducts };
