import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// core
// utils
// locales
// store
// types
import { IPropsType } from './types';
import { EGameType } from 'types/models/game';
// components(atoms)

// styles
import './index.scss';
import { gameTypeSlug } from '../../../../utils/commons';

// data

// func
const TableCellImage = ({ productId, alt, src, gameType }: IPropsType) => {
  return (
    <RouterLink
      to={`/market/${gameTypeSlug(gameType)}/${productId}`}
      className={
        'table-cell-img' +
        (gameType === EGameType.CSGO
          ? ' table-cell-img--csgo'
          : ' table-cell-img--dota')
      }
    >
      <img src={src} alt={alt ? alt : ''} />
    </RouterLink>
  );
};

TableCellImage.defaultProps = {
  gameType: EGameType.CSGO,
} as { gameType: EGameType };

export { TableCellImage };
