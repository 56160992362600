import React, { useEffect, useState } from 'react';
import { Button, Table, Typography } from 'antd';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';
import {
  usePendingInventorySocket,
  useQueryFetchActiveInventory,
  useQueryFetchProfileInfo,
  useQueryFetchUserBalance,
} from 'hooks/api';
// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import {
  EGameType,
  EInventoryPendingState,
  EInventoryPendingType,
} from 'types/models';
import { ECardSizeType } from 'components/features/Cards/EmptyCard/types';
import { ELoaderTypeType } from 'components/atoms/AppLoader/types';
// components
import { AppLoader, ModalTeleport } from 'components/atoms';
import { StatIcon } from 'components/icons';
import {
  EmptyCard,
  PriceModal,
  TableCellFloat,
  TableCellImage,
  TableCellStickers,
} from 'components/features';
import { InventoryPendingTimer } from './InventoryPendingTimer';
import { InventoryHead } from '../InventoryHead';
// styles
import './index.scss';

//data
import { formatPrice } from '../../../../utils/commons';
import { queryClient } from '../../../../config/queryClient';
import { TableCellName } from '../../../../components/features/TableCells/TableCellName';
import { TableCellRarity } from '../../../../components/features/TableCells/TableCellRarity';

//data
const { Text } = Typography;

const transformData = (data: any) => {
  let { item, price, state, tradeOfferId, type, createdAt, tID, expiredAt } =
    data;
  let {
    sku,
    image,
    preview,
    name,
    float,
    shortenExterior,
    stickers,
    keychains,
    tag,
    status,
    marketName,
    originalMarketName,
    provider,
    gems,
    exterior,
    seo,
  } = item;
  let expiredHideAt = new Date(
    new Date(expiredAt).getTime() + 30000,
  ).toISOString(); // 30sec
  return {
    tID,
    createdAt,
    expiredAt,
    expiredHideAt,
    id: sku,
    state,
    tradeOfferId,
    type,
    status,
    price,
    img: image ? image : preview,
    name,
    shortenExterior,
    float,
    rarity: tag ? tag.rarity : null,
    marketName,
    provider,
    originalMarketName,
    exterior,
    isTransformed: true,
    stickers,
    keychains,
    gems,
    seo,
  };
};

const getUpdateTableData = (data: any) => {
  return data.reduce((acc, item) => {
    let isCanShow = true;

    let expiredAtTime = new Date(item.expiredAt).getTime();
    let expiredHideAtTime = new Date(item.expiredHideAt).getTime();

    if (
      expiredAtTime <= Date.now() &&
      [EInventoryPendingState.Canceled, EInventoryPendingState.Success].indexOf(
        item.state,
      ) > -1
    ) {
      isCanShow = false;
    } else if (expiredHideAtTime <= Date.now()) {
      isCanShow = false;
    }

    if (
      expiredAtTime <= Date.now() &&
      [EInventoryPendingState.Canceled, EInventoryPendingState.Success].indexOf(
        item.state,
      ) === -1
    ) {
      item = { ...item, state: EInventoryPendingState.Timeout };
    }

    return isCanShow ? [...acc, item] : acc;
  }, []);
};

const renderTextType = (state: any) => {
  if (
    [
      EInventoryPendingState.Created,
      EInventoryPendingState.NeedBuyerApprove,
      EInventoryPendingState.NeedSellerApprove,
    ].indexOf(state) > -1
  ) {
    return 'warning';
  } else if (
    [EInventoryPendingState.Canceled, EInventoryPendingState.Timeout].indexOf(
      state,
    ) > -1
  ) {
    return 'danger';
  } else {
    return 'success';
  }
};
const renderStateText = (
  state: EInventoryPendingState,
  t: any,
  type: EInventoryPendingType,
) => {
  if (state === EInventoryPendingState.Created) {
    return t('inventoryPendingStateCreated');
  } else if (state === EInventoryPendingState.Success) {
    return t('inventoryPendingStateSuccess');
  } else if (state === EInventoryPendingState.Canceled) {
    return t('inventoryPendingStateCanceled');
  } else if (state === EInventoryPendingState.NeedBuyerApprove) {
    if (type === EInventoryPendingType.Buy) {
      return t('inventoryPendingStateBuyer');
    } else {
      return t('inventoryPendingStateSellerReversse');
    }
  } else if (state === EInventoryPendingState.NeedSellerApprove) {
    if (type === EInventoryPendingType.Buy) {
      return t('inventoryPendingStateBuyerReverse');
    } else {
      return t('inventoryPendingStateSeller');
    }
  } else {
    return t('inventoryPendingStateTimeout');
  }
};
const InventoryPending = () => {
  const { t } = useTranslation();
  const { gameType, sModalOpen } = useAppStore();
  const [enabled, setEnabled] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [enabledSocket, setEnabledSocket] = useState<boolean>(false);
  useQueryFetchUserBalance(true);

  const { data: profileData, isLoading: isLoadingProfileData } =
    useQueryFetchProfileInfo(false);

  const { data: pendingInventoryData, isLoading: isLoadingPendingInventory } =
    useQueryFetchActiveInventory({
      payload: { provider: gameType },
      enabled,
    });

  const lastTrade = usePendingInventorySocket(enabledSocket, profileData?.id);

  useEffect(() => {
    setEnabled(true);
    const intervalId = setInterval(() => {
      setTableData(prevData => {
        return getUpdateTableData(prevData);
      });
    }, 1000);

    return () => {
      setTableData([]);
      queryClient.invalidateQueries(['useGetActiveInventory']);
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingProfileData && profileData) {
      setEnabledSocket(true);
    }
  }, [profileData, isLoadingProfileData]);

  useEffect(() => {
    if (lastTrade) {
      if (lastTrade.state === EInventoryPendingState.Created) {
        if (lastTrade.item?.provider === gameType) {
          //---add new item from socket
          if (!tableData.find(el => el.tID === lastTrade.tID)) {
            setTableData(prevData => {
              return [transformData(lastTrade), ...prevData];
            });
          }
        }
      } else {
        if (tableData.find(el => el.tID === lastTrade.tID)) {
          //---bug with shallow copy, can't use spread function or editedIndex
          let newTableData = tableData.map(el => {
            return el.tID === lastTrade.tID ? { ...el, ...lastTrade } : el;
          });
          setTableData(newTableData);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTrade]);

  useEffect(() => {
    if (pendingInventoryData && !isLoadingPendingInventory) {
      //---check and add new items from rest api to table
      let newPendingInventoryData = pendingInventoryData.reduce((acc, item) => {
        return tableData.find(el => el.tID === item.tID)
          ? acc
          : [transformData(item), ...acc];
      }, []);

      setTableData(prevData => {
        return [...newPendingInventoryData, ...prevData];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingInventoryData, isLoadingPendingInventory]);

  const onShowPriceStat = data => {
    setSelectedItem(data);
    sModalOpen('priceTrade');
  };

  const onAcceptProduct = data => {
    window.open(`https://steamcommunity.com/tradeoffer/${data}`);
  };

  const renderColumns = [
    {
      key: 'type',
      dataIndex: 'type',
      render: type => (
        <div className="ant-table-cell__status">
          {type === EInventoryPendingType.Sell
            ? t('InventoryPendingSell')
            : t('InventoryPendingBuy')}
        </div>
      ),
    },
    {
      key: 'img',
      dataIndex: 'img',
      render: (_, record) => (
        <TableCellImage
          productId={
            record.seo?.sku
              ? record.seo.sku + '?id=' + record.id
              : record.id + '?id=' + record.id
          }
          src={record.img}
          gameType={record.provider}
          alt={''}
        />
      ),
    },
    {
      key: 'name',
      dataIndex: 'name',
      render: (_, record) => (
        <TableCellName
          first={record.marketName.first}
          second={record.marketName.second}
          originalMarketName={record.originalMarketName}
          productId={
            record.seo?.sku
              ? record.seo.sku + '?id=' + record.id
              : record.id + '?id=' + record.id
          }
          provider={record.provider}
        />
      ),
    },
    {
      key: 'float',
      dataIndex: 'float',
      render: (_, record) => (
        <>
          {record.provider === EGameType.CSGO ? (
            <TableCellFloat
              float={record.float}
              shortenExterior={record.shortenExterior}
            />
          ) : (
            <TableCellRarity rarity={record.rarity} />
          )}
        </>
      ),
    },
    {
      key: 'stickers',
      dataIndex: 'stickers',
      width: '50%',
      render: (_, record) => (
        <TableCellStickers
          gameType={record.provider}
          collection={
            record.provider === EGameType.CSGO
              ? [...record.stickers, ...record.keychains]
              : record.gems
          }
        />
      ),
    },
    {
      key: 'price',
      dataIndex: 'price',
      render: price => (
        <div className="ant-table-cell__nowrap">
          {t('price')}:{' '}
          <span className="ant-table-cell__value">$ {formatPrice(price)}</span>
        </div>
      ),
    },
    {
      key: 'stat',
      render: (_, record) => (
        <Button
          type={EButtonTypeType.Text}
          className={'ant-btn-text--gray'}
          icon={<StatIcon />}
          onClick={() => onShowPriceStat(record)}
        />
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        <div className="ant-table-cell__nowrap">
          {t('status')}:{' '}
          <Text type={renderTextType(record.state)}>
            {renderStateText(record.state, t, record.type)}{' '}
            {record.state !== EInventoryPendingState.Success &&
              record.state !== EInventoryPendingState.Canceled &&
              record.state !== EInventoryPendingState.Timeout && (
                <InventoryPendingTimer expiredAt={record.expiredAt} />
              )}
          </Text>
        </div>
      ),
    },
    {
      key: 'action',
      render: (_, record) => (
        <>
          {record.state === EInventoryPendingState.NeedBuyerApprove &&
            record.type === EInventoryPendingType.Buy && (
              <Button
                block
                size={EButtonSizeType.Middle}
                ghost
                disabled={!record.tradeOfferId}
                type={EButtonTypeType.Primary}
                onClick={() => onAcceptProduct(record?.tradeOfferId)}
              >
                {t('inventoryPendingBtnReceive')}
              </Button>
            )}
        </>
      ),
    },
  ];

  return (
    <div className="inventory">
      <>
        <InventoryHead
          isInventory={false}
          categories={null}
          search={null}
          sort={null}
          isSteamUpdateProcessed={undefined}
          onChangeCategory={undefined}
          onChangeSort={undefined}
          onReset={undefined}
          onChangeSearch={undefined}
          onSteamReload={undefined}
        />
        <div className="inventory-pending">
          {isLoadingPendingInventory && !pendingInventoryData ? (
            <div className="inventory-products__refetch-loader">
              <AppLoader type={ELoaderTypeType.Relative} />
            </div>
          ) : (
            <>
              <Table
                loading={isLoadingPendingInventory}
                showHeader={false}
                className="inventory-pending__table"
                columns={renderColumns}
                // @ts-ignore
                dataSource={tableData}
                rowKey="id"
                scroll={{ x: '1200px' }}
                locale={{
                  emptyText: (
                    <div className="ant-table-cell__table-empty">
                      <EmptyCard
                        size={ECardSizeType.Small}
                        title={t('emptyRequestTitle')}
                        description={t('emptyRequestDesc')}
                      />
                    </div>
                  ),
                }}
                pagination={false}
              />
            </>
          )}
        </div>
      </>

      <ModalTeleport
        centered
        modalId={'priceTrade'}
        width={1330}
        modalClass={'price-modal__wrapper'}
      >
        <PriceModal product={selectedItem} modalId={'priceTrade'} />
      </ModalTeleport>
    </div>
  );
};

export { InventoryPending };
