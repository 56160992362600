// core
import React from 'react';

// utils

// locales

// store

// types

// components

// styles
import './index.scss';

// data

// func
const EmptyLayout = ({ children }: any) => {
  return (
    <div className="app-layout app-layout--static">
      <div className="app-layout__content">{children}</div>
    </div>
  );
};

export { EmptyLayout };
